<template>
  <div class="home container-fluid">
    <mdb-row  >
      <mdb-col class="col-sm-12">
        <revenues></revenues>
      </mdb-col>
      
      <mdb-col sm="12" md="6" lg="6" class="mb-4">
        <mdb-card cascade narrow>
          <mdb-view cascade class="gradient-card-header blue"
            ><h5 class="mb-0">Evolution du chiffre d'affaire des factures de vente et des factures d'avoir</h5></mdb-view
          >
          <mdb-card-body>
              <revenues-fv-fa></revenues-fv-fa>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="12" md="6" lg="6" class="mb-4">
        <mdb-card cascade narrow>
          <mdb-view cascade class="gradient-card-header blue"
            ><h5 class="mb-0">Evolution du chiffre d'affaire des facture de vente à l'exportation et des factures avoir à l'exportation</h5></mdb-view
          >
          <mdb-card-body>
              <revenues-ev-ea></revenues-ev-ea>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="12" md="12" lg="12" class="mb-4">
        <mdb-card cascade narrow>
          <mdb-view cascade class="gradient-card-header blue"
            ><h5 class="mb-0">Evolution du montant de TVA</h5></mdb-view
          >
          <mdb-card-body>
              <mtva-evolution></mtva-evolution>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="12" md="6" lg="6" v-if="$store.state.auth.connected">
        <sites></sites>
      </mdb-col>
      <mdb-col sm="12" md="6" lg="6" v-if="$store.state.auth.connected">
        <users></users>
      </mdb-col>
      
    </mdb-row>
  </div>
</template>

<script>
import Sites from "@/tba/views/homes/modules/SiteReports"
import Users from "@/tba/views/homes/modules/UserReports"

import Revenues from "@/tba/views/homes/modules/RevenuesReports"
import RevenuesFvFa from "@/tba/views/homes/modules/RevenuesFvFaByMonth"
import RevenuesEvEa from "@/tba/views/homes/modules/RevenuesEvEaByMonth"
import MtvaEvolution from "@/tba/views/overviews/modules/TvaEvolution"
import {
	mdbRow,
	mdbCol,
	mdbCard,
	mdbCardBody,
	mdbView,
} from "mdbvue";
export default {
  name: 'home',
  metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - VUE D\'ENSEMBLE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
  components: {
    Revenues,
    RevenuesFvFa,
    RevenuesEvEa,
    MtvaEvolution,
    Sites,
    Users,


    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbView,
  }
}
</script>
