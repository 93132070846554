<template>
	<div class="mtva-reports">
	<!-- Chart -->
	<mdb-line-chart
	v-if="chartRender"
		:data="lineChartData"
		:options="lineChartOptions"
		:height="300"
	></mdb-line-chart>
	</div>
</template>

<script>
import {
mdbLineChart,
} from "mdbvue";
export default {
name:'mtva-reports',
components: {
	mdbLineChart,
},

data(){
	return{
		revenues:[],
		chartRender:false,
		lineChartData: {
			labels: [],
			datasets: [
			{
				label: "Montant TVA",
				backgroundColor: "rgba(233, 30, 99, 0.1) ",
				borderColor: "rgba(233, 30, 99, 1)",
				pointBackgroundColor: "transparent",
				borderWidth: 0.7,
				data: []
			},
			]
		},
		lineChartOptions: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [
					{
					gridLines: {
						display: true,
						color: "rgba(255, 255, 255, 0.2)"
					}
					}
				],
				yAxes: [
					{
					gridLines: {
						display: true,
						color: "rgba(255, 255, 255, 0.2)"
					}
					}
				]
			}
		},
	}
},

methods:{
	async getMTva(){
		this.$nprogress.start()
		await this.$store.dispatch('report/mtvaReports')
		.then((response)=>{
			this.lineChartData.labels = [];
			this.lineChartData.datasets[0].data = []
			this.chartRender = false
			this.lineChartData.labels = Object.keys(response.data.mtva)
			this.lineChartData.datasets[0].data = Object.values(response.data.mtva)
			this.$nprogress.done()
			this.$nextTick(()=>{
				this.chartRender = true
			})
		})
		.catch((error)=>{
			this.$nprogress.done()
			this.$notify({
				message: error.response.data.message  || error.response.data.error,
				type: 'danger'
			})
		});
	}
},
created(){
	this.getMTva()
}
}
</script>
